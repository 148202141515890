:root {
  font-family: Montserrat, sans-serif;
}

* {
  padding-inline-start: 0;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  background-color: #f4f6f9;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.container {
  width: 1370px;
  min-height: 100%;
  margin: auto;
  padding-left: 65px;
  padding-right: 65px;
  position: relative;
}

.events__container {
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 180px;
  display: flex;
}

.topHeader {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
}

.header {
  width: 100%;
  background-color: #f3f5fd;
  background-image: url("tło yellow.1edad4dd.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: contain;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.logo {
  color: #040c34;
  letter-spacing: .5px;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 0;
  font-size: 31px;
  font-weight: 800;
  display: flex;
}

.logo__text {
  color: #040c34;
  margin: 0;
  padding-bottom: 4px;
  padding-left: 25px;
  font-size: 15px;
  font-weight: 400;
}

.logo__textLastword {
  color: #fff;
  font-weight: 600;
}

.logo__lastLiter {
  color: #fff;
  font-weight: 800;
}

.yourNotific {
  color: #fff;
  flex-direction: column;
  display: flex;
}

.button__ulubione {
  height: 38px;
  color: #fff;
  letter-spacing: .4px;
  background-color: #040c34;
  border: none;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

a.button__ulubione:visited {
  color: #fff;
}

.empty__list {
  color: #8796ac;
  padding-bottom: 50px;
  font-size: 17px;
  font-weight: 400;
}

.container__favoritesEventandBanner {
  padding-bottom: 180px;
}

.boxAlert {
  padding-bottom: 20px;
}

.alert {
  width: 18px;
  padding-left: 25px;
}

.heart {
  width: 20px;
}

.titleH1 {
  color: #f8ffa6;
  letter-spacing: 2%;
  text-align: left;
  margin-top: 0;
  padding-top: 80px;
  font-size: 25px;
  font-weight: 500;
}

.underTitleH1 {
  padding-top: 62px;
}

.underTitleH1, .notific {
  color: #fff;
  text-align: left;
  font-size: 19px;
  font-weight: 400;
  line-height: 10px;
}

.textYellow {
  color: #f8ffa6;
}

.textYellow2 {
  color: #f8ffa6;
  text-decoration: underline;
}

.boxInput, .wrap__h1Inputs {
  margin: auto;
  padding-top: 70px;
  padding-bottom: 32px;
}

.inputs {
  flex-direction: row;
  place-content: space-between center;
  gap: 1px;
  display: flex;
}

.input__search {
  border-radius: 0;
}

.input__item {
  width: 250px;
  height: 50px;
  border: 8px;
  border-radius: 0;
  outline-style: none;
  margin-top: 5px;
  font-size: 17px;
  display: flex;
}

::placeholder {
  color: #bfc7d4;
  justify-content: center;
  padding-left: 0;
  display: flex;
}

.app .autoComplete_wrapper > .input__search {
  width: 250px;
  height: 50px;
  color: #040c34;
  border: 0;
  border-radius: 8px 0 0 8px;
  padding-left: 42px;
  font-size: 17px;
}

.app .autoComplete_wrapper > .input__location {
  width: 250px;
  height: 50px;
  color: #040c34;
  border: 0;
  border-radius: 0;
  padding-left: 42px;
  font-size: 17px;
}

.app .autoComplete_wrapper > input::placeholder {
  color: #bfc7d4;
  font-size: 17px;
}

.app .autoComplete_wrapper > ul {
  max-height: 270px;
  border-radius: 0;
  margin: .1rem 0 0;
  overflow-y: hidden;
}

.autoComplete_wrapper > ul > li mark {
  color: #040c34 !important;
}

.autoComplete_wrapper > ul > li:hover {
  background-color: #f3f5fd !important;
}

.autoComplete_wrapper > ul > li {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  color: #040c34 !important;
}

.input__flex {
  display: inline-block;
  position: relative;
}

.input__close:before {
  content: "";
  height: 14px;
  width: 14px;
  cursor: pointer;
  background-image: url("close.36a8f76c.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 22px;
  right: 13px;
}

.input__searchSvg:after {
  content: "";
  height: 20px;
  width: 20px;
  background-image: url("search.cb106ec4.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 17px;
  left: 13px;
}

.input__locationSvg:after {
  content: "";
  height: 20px;
  width: 20px;
  background-image: url("location.45eebdc7.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 17px;
  left: 13px;
}

.input__calendarSvg:after {
  content: "";
  height: 20px;
  width: 20px;
  background-image: url("calendar.43a1b233.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 17px;
  left: 13px;
}

.button__search {
  height: 50px;
  width: 180px;
  color: #fff;
  cursor: pointer;
  background-color: #040c34;
  border: 8px;
  border-radius: 0 8px 8px 0;
  margin-top: 5px;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 550;
}

input[type="text"] {
  padding: 0 42px;
}

.notific {
  padding-bottom: 25px;
  font-size: 17px;
}

.type__events {
  background-color: #f3f5fd;
  flex-direction: row;
  justify-content: start;
  gap: 40px;
  padding-left: 227px;
  display: flex;
}

.type__yellowEvent {
  width: 103px;
  height: 93px;
  border-color: #040c34 1px solid;
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  list-style: none;
  display: flex;
}

.type__svg {
  max-height: 35%;
  fill: #0d4bc1;
  padding-top: 12px;
}

.type__text {
  color: #8796ac;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}

.tagSearch {
  background: #fff;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  padding-top: 30px;
  padding-left: 65px;
  display: flex;
}

.tagSearchButton {
  color: #eff200;
  cursor: pointer;
  background-color: #233d63;
  border: none;
  border-radius: 3px;
  padding: 7px;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.event__polecaText {
  color: #040c34;
  margin: 0;
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 800;
}

.events__cards, .event__cards {
  background: #f3f5fd;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 70px;
  list-style: none;
  display: flex;
}

.event__cards {
  padding-bottom: 60px;
}

.container__similarEventandBanner {
  padding-bottom: 180px;
}

.event__wrap {
  width: calc(25% - 22.5px);
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  display: flex;
}

.events__figure {
  margin: 0;
  display: block;
}

.events__figure:hover {
  cursor: pointer;
  box-shadow: 0 1px 1px #0000001f, 0 4px 4px #0000000f, 1px 4px 6px #00000029;
}

.events__photo {
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.events__figcaption {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.events__figcaption:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)), .events__figcaption:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.div__load__more {
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
  display: flex;
}

.load__more {
  width: 290px;
  height: 50px;
  color: #040c34;
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  display: flex;
}

.remove__loadMoreButton, .remove__eventikPoleca {
  display: none;
}

.events__artistName, .events__place, .events__data {
  text-align: center;
  color: #233d63;
  margin: 0;
  line-height: 20px;
}

.events__artistName {
  width: 260px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
  padding-bottom: 14px;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
}

.events__place {
  width: 270px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 7px;
  overflow: hidden;
}

.events__place, .events__data {
  color: #74839b;
  font-size: 15px;
}

.event__icons {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
}

.event__svgTicket {
  left: 8 px;
  stroke: #233d63;
  fill: #233d63;
  stroke-width: .9px;
  padding-right: 5px;
  position: relative;
  top: 1px;
}

.event__moreButton {
  color: #040c34;
  text-align: center;
  letter-spacing: .4px;
  cursor: pointer;
  height: 40px;
  background-color: #fff;
  border: 1.6px solid #040c34;
  border-radius: 20px;
  padding: 0 45px;
  font-size: 14px;
  font-weight: 500;
}

.event__moreButton:hover {
  color: #040c34;
  border: 1.8px solid #fbe307;
  font-weight: 600;
}

.event__deleteLove {
  color: #040c34;
  text-align: center;
  letter-spacing: .7px;
  cursor: pointer;
  height: 40px;
  background-color: #fff;
  border: 1.6px solid #040c34;
  border-radius: 20px;
  padding: 0 35px;
  font-size: 14px;
  font-weight: 500;
}

.event__deleteLove:hover {
  color: #040c34;
  border: 1.8px solid #fbe307;
  font-weight: 600;
}

.event__navi {
  color: #8796ac;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
  font-size: 17px;
}

.event__imgParam {
  max-width: 580px;
}

.event__container {
  flex-direction: row;
  justify-content: left;
  display: flex;
}

.event__headings, .event__headName, .event__similarEventText {
  color: #233d63;
  margin: 0;
  font-size: 33px;
  font-weight: 600;
}

.event__info {
  flex-direction: column;
  padding-left: 50px;
  display: flex;
}

.event__loveShare {
  flex-direction: row;
  gap: 10px;
  padding-top: 20px;
  display: flex;
}

.event__svgHeart {
  stroke-width: .9px;
  padding-right: 15px;
  position: relative;
  top: 1px;
  left: 8px;
}

.event__loveButton, .event__shareButton {
  color: #233d63;
  letter-spacing: .4px;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #233d63;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px;
  font-size: 15px;
  display: flex;
}

.event__infoWraper {
  flex-direction: column;
  display: flex;
}

.event__description, .event__place, .event__date, .event__price {
  color: #8796ac;
  font-size: 21px;
  line-height: 30px;
}

.event__buyTicket {
  max-width: 400px;
  color: #040c34;
  text-align: center;
  letter-spacing: .4px;
  cursor: pointer;
  background-color: #fbe307;
  border: none;
  border-radius: 0;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  font-size: 26px;
  font-weight: 600;
  display: flex;
}

a {
  text-decoration: none;
}

a:visited, a:active, a:link {
  color: #040c34;
}

.event__svgBuyTicket {
  width: 36px;
  padding-left: 70px;
  padding-right: 30px;
}

.event__similarEventText {
  color: #040c34;
  padding-top: 115px;
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: 800;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  color: #040c34 !important;
}

.modal__btn-primary {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #040c34 !important;
  background-color: #eff200 !important;
}

.flatpickr-calendar {
  width: 357px !important;
  border-radius: 0 !important;
}

.flatpickr-months .flatpickr-month {
  padding-top: 5px;
  padding-bottom: 10px;
  font-family: Montserrat, sans-serif;
  color: #040c34 !important;
  font-weight: 600 !important;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  fill: #040c34;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #27b1ff !important;
}

span.flatpickr-weekday {
  font-family: Montserrat, sans-serif;
  color: #040c34 !important;
  font-weight: 600 !important;
}

.flatpickr-weekdaycontainer {
  padding-top: 30px;
  padding-bottom: 30px !important;
}

.flatpickr-day {
  color: #040c34 !important;
}

.nextMonthDay, .prevMonthDay {
  color: #b6b8ba !important;
}

.flatpickr-innerContainer {
  display: block;
  justify-content: center !important;
}

.dayContainer {
  padding-top: 10px !important;
  padding-bottom: 15px !important;
}

.flatpickr-day.today {
  border-color: #dde4f3 !important;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #dde4f3 !important;
  border-color: #dde4f3 !important;
}

.flatpickr-day:hover {
  background: #dde4f3 !important;
  border-color: #dde4f3 !important;
}

.flatpickr-day.selected {
  border-radius: 0;
  color: #040c34 !important;
  background: #dde4f3 !important;
  border-color: #dde4f3 !important;
  border-radius: 50px !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #fff !important;
}

.footer {
  height: 140px;
  width: 100vw;
  background-color: #fbe307;
  position: absolute;
  bottom: 0;
}

.logo__footer {
  color: #040c34;
  flex-direction: row;
  justify-content: center;
  padding-top: 35px;
  font-size: 31px;
  font-weight: 700;
  display: flex;
}

.logo__textFooter {
  color: #040c34;
  text-align: center;
  margin: 0;
  padding-top: 11px;
  font-size: 13px;
  font-weight: 400;
}

.footer__MJ {
  font-weight: 600;
}

.ticketmaster__giftCard {
  max-width: 65%;
}

.ticketmaster__container {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media (max-width: 1499px) {
  .container {
    width: 1000px;
  }

  .input__item {
    width: 160px;
  }

  .event__wrap {
    width: calc(33% - 20px);
  }

  .app .autoComplete_wrapper > .input__search, .app .autoComplete_wrapper > .input__location {
    width: 160px;
  }

  .button__search {
    width: 130px;
  }

  .event__imgParam {
    max-width: 420px;
  }
}

@media (max-width: 1149px) {
  .container {
    width: 650px;
  }

  .event__wrap {
    width: calc(50% - 15px);
  }

  .logo__text, .logo__textLastword {
    display: none;
  }

  .boxInput {
    padding-top: 5px;
  }

  .input__close:before {
    content: "";
    position: absolute;
    right: 15px;
  }

  .inputs {
    width: 100%;
    flex-direction: column;
    margin: 0;
  }

  .input__item {
    width: 400px;
  }

  .input__calendar {
    border-radius: 8px;
  }

  .app .autoComplete_wrapper > .input__search, .app .autoComplete_wrapper > .input__location {
    width: 400px;
    border-radius: 8px;
  }

  .button__search {
    width: 484px;
    border-radius: 8px;
  }

  .event__container {
    flex-direction: column;
    display: flex;
  }

  .event__headings, .event__headName, .event__similarEventText {
    max-width: 470px;
    font-size: 27px;
  }

  .event__headName {
    padding-top: 20px;
  }

  .event__imgParam {
    max-width: 450px;
  }

  .event__info {
    padding-left: 0;
  }

  .event__buyTicket {
    max-width: 320px;
    font-size: 20px;
  }

  .event__svgBuyTicket {
    width: 26px;
    padding-left: 50px;
    padding-right: 30px;
  }
}

@media (max-width: 799px) {
  .container {
    width: 310px;
    padding-left: 9px;
    padding-right: 9px;
  }

  .logo {
    font-size: 26px;
  }

  .event__wrap {
    width: 100%;
    padding-top: 3px;
    padding-bottom: 0;
  }

  .disable__mobile {
    display: none;
  }

  .inputs {
    width: 100%;
    flex-direction: column;
    margin: 0;
  }

  .wrap__h1Inputs {
    width: 300px;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .autoComplete_wrapper {
    width: 310px;
    padding: 0;
  }

  .button__ulubione {
    height: 30px;
    padding-bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
  }

  .heart {
    width: 18px;
  }

  .yourNotific {
    padding: 0;
  }

  .input__calendar {
    border-radius: 8px;
  }

  .event__polecaText {
    font-size: 23px;
  }

  #datetime-picker {
    width: 246px;
    padding-left: 42px;
    padding-right: 22px;
  }

  .input__flex {
    width: 310px;
  }

  .boxInput {
    padding-top: 1px;
  }

  .app .autoComplete_wrapper > .input__search, .app .autoComplete_wrapper > .input__location {
    width: 246px;
    border-radius: 8px;
    padding-left: 42px;
    padding-right: 22px;
  }

  .input__close:before {
    content: "";
    height: 14px;
    width: 14px;
    position: absolute;
    right: 15px;
  }

  .button__search {
    width: 310px;
    border-radius: 8px;
  }

  .events__photo {
    width: 100%;
    height: 100%;
  }

  .events__place {
    width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 7px;
    overflow: hidden;
  }

  .events__artistName {
    width: 280px;
    padding-top: 5px;
    font-size: 17px;
  }

  .events__place, .events__data {
    padding-top: 5px;
    font-size: 16px;
  }

  .event__moreButton {
    height: 35px;
    padding: 0 35px;
    font-size: 12px;
  }

  .event__imgParam {
    max-width: 310px;
    padding-top: 30px;
  }

  .event__headings, .event__headName, .event__similarEventText {
    max-width: 290px;
    font-size: 20px;
  }

  .event__similarEventText {
    padding-top: 50px;
  }

  .event__navi {
    display: none;
  }

  .event__loveButton, .event__shareButton {
    padding: 5px 8px;
    font-size: 12px;
  }

  .event__svgHeart {
    width: 15px;
  }

  .event__description, .event__place, .event__date, .event__price {
    color: #8796ac;
    font-size: 17px;
    line-height: 22px;
  }

  .load__more {
    font-size: 14px;
  }

  .logo__footer {
    font-size: 25px;
  }

  .ticketmaster__giftCard {
    max-width: 100%;
  }
}

/*# sourceMappingURL=index.806217de.css.map */
