$containerWidth: 1370px;

:root {
  font-family: 'Montserrat', sans-serif;
}
* {
  padding-inline-start: 0px;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  position: relative;
  background-color:#f4f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.container {
  width: $containerWidth;
  padding-left: 65px;
  padding-right: 65px;
  margin: auto;
  min-height: 100%;
  position:relative;
  // margin-right: 0px;
}

.events__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 180px;
}

.topHeader {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 35px;
  // background-color: #ffffff;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F3F5FD;

  // max-width: 1280px;
  background-image: url("/src/assets/images/tło yellow.jpg");
  // background: rgb(163, 160, 160);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: contain;

}
.logo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: #040C34;
  // padding-left:247px;
  // padding-bottom: 45px;
  padding-top: 0px;
  font-size: 31px;
  font-weight: 800;
  letter-spacing: 0.5;
}

.logo__text{
  margin: 0px;
  padding-left: 25px;
  padding-bottom: 4px;
  font-size: 15px;
  color: #040C34;
  font-weight: 400;
}
.logo__textLastword {
  color: #ffffff;
  font-weight: 600;
}
.logo__lastLiter{
  color: #ffffff;
  font-weight: 800;
}
.yourNotific {
  display: flex;
  flex-direction: column;
  color: white;
  // padding: 30px 0px 30px 0px;
}
.button__ulubione {
  background-color: #040C34;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  gap: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-weight: 500;
}
a.button__ulubione:visited {
  color: white;
}

.empty__list {
  color: #8796AC;
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 50px;
}
.container__favoritesEventandBanner {
  padding-bottom: 180px;
}

.boxAlert {
  padding-bottom: 20px;
}
.alert {
  width: 18px;
  padding-left: 25px;
}

.heart {
  width: 20px;
}


.titleH1 {
  font-size: 25px;
  font-weight: 500;
  color: #F8FFA6;
  letter-spacing: 2%;
  text-align: left;
  padding-top: 80px;
  margin-top: 0px;
}

.underTitleH1 {
  padding-top: 62px;
}

.underTitleH1, .notific {
  font-size: 19px;
  font-weight: 400;
  color: white;
  line-height: 10px;
  text-align: left;

}

.textYellow {
  color: #F8FFA6 ;
}
.textYellow2 {
  color: #F8FFA6;
  text-decoration: underline;
}

.boxInput, .wrap__h1Inputs {
  // display: flex;
  // flex-wrap: wrap;
  // max-width: 690px;
  margin: auto;
  padding-top:  70px;
  padding-bottom: 32px;
 
}

.inputs{
  // width: 1200px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1px;
  align-content: space-between;
  // background-color: #8796AC;
}
.input__search {
  border-radius: 0px;
}

.input__item {
  display: flex;
  // flex-basis: calc(100%/2 - 10px);
  width: 250px;
  height: 50px;
  margin-top: 5px;
  border-radius: 0px;
  border: 8px;
  outline-style: none;
  font-size: 17px;
}
::placeholder {
  color:#bfc7d4;
  padding-left: 0px;
  display: flex;
  justify-content: center;
}
// }

.app {
  & .autoComplete_wrapper > .input__search {
    width: 250px;
    border-radius: 8px 0px 0px 8px;
    height: 50px;
    border: 0px;
    padding-left: 42px;
    color: #040C34;
    font-size: 17px;
  }
  & .autoComplete_wrapper > .input__location {
    width: 250px;
    border-radius: 0px;
    height: 50px;
    border: 0px;
    padding-left: 42px;
    color: #040C34;
    font-size: 17px;
  }
 
  
  & .autoComplete_wrapper > input::placeholder {
    color:  #bfc7d4;
    // padding-left: 20px;
    font-size: 17px
  }
  
  & .autoComplete_wrapper > ul {
    border-radius: 0px;
    max-height: 270px;
    margin: 0.1rem 0 0 0;
    overflow-y: hidden;
  
  }
}

.autoComplete_wrapper > ul > li mark {
  color:#040C34 !important
}
.autoComplete_wrapper > ul > li:hover {
  background-color: #f3f5fd !important;
}
.autoComplete_wrapper > ul > li{
  color: #040C34 !important;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}
.input__flex {
  position: relative;
  display: inline-block;
}

.input__close::before {
  position: absolute;
  content: "";
    top: 22px;
    right: 13px;
    height: 14px;
    width: 14px;
    background-image: url(/src/assets/images/close.svg);
    background-repeat: no-repeat;
    cursor: pointer;
}
.input__searchSvg::after {
  position: absolute;
  content: "";
    top: 17px;
    left: 13px;
    height: 20px;
    width: 20px;
    background-image: url(/src/assets/images/search.svg);
    background-repeat: no-repeat;
}

.input__locationSvg::after {
  position: absolute;
  content: "";
    top: 17px;
    left: 13px;
    height: 20px;
    width: 20px;
    background-image: url(/src/assets/images/location.svg);
    background-repeat: no-repeat;
}

.input__calendarSvg::after {
  position: absolute;
  content: "";
    top: 17px;
    left: 13px;
    height: 20px;
    width: 20px;
    background-image: url(/src/assets/images/calendar.svg);
    background-repeat: no-repeat;
}

.button__search {
  height: 50px;
  width: 180px;
  background-color: #040C34;
  border-radius: 0px 8px 8px 0px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 550;
  border: none;
  padding: 0;
  margin-top: 5px;
  padding: 0px 20px 0px 20px;
  border: 8px;
  cursor: pointer;
}

input[type=text]{
  padding:0px 42px;
}

.notific {
  padding-bottom: 25px;
  font-size: 17px;
}
.type__events {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 40px;
  padding-left: 227px;
  background-color: #f3f5fd;
  // padding: 0px;
}

.type__yellowEvent {
  width: 103px;
  height: 93px;
  // background-color: white;
  border-radius: 10px ;
  border-color: #040C34 1px solid;
  list-style: none;
  // margin-left: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  // &:hover {
  //   // background-color: #F8FFA6;
  // }
}
.type__svg {
  padding-top: 12px;
  max-height: 35%;
  fill: #0d4bc1;
}

.type__text {
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color:#8796AC;
}
.tagSearch {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  padding-top: 30px;
  padding-left: 65px;
  background: white;
}

.tagSearchButton {
  border: none;
  background-color:#233D63;
  color:#EFF200;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  padding: 7px;
  border-radius: 3px;
  cursor: pointer;
}

.event__polecaText {
  font-size: 30px;
  font-weight: 800;
  color:#040C34;
  // padding-left: 240px;
  padding-bottom: 10px;
  // padding-top: 50px;
  margin: 0px;
}

$gap: 30px;
$cols: 4;

.events__cards,
.event__cards {
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  gap: $gap;
  list-style: none;
  padding-top: 20px;
  background: #f3f5fd;
  padding-bottom: 70px;
}
.event__cards{
 padding-bottom: 60px;
}
.container__similarEventandBanner {
  padding-bottom: 180px;
}
.event__wrap {
  // margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  width: calc(25% - ($cols - 1)/$cols * $gap);
}

.events__figure {
  // width: 10%;
  display: block;
  margin: 0px;
  // padding-bottom: 30px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.06),
      1px 4px 6px rgba(0, 0, 0, 0.16);
  }
  // margin-inline-start: 0px;
  // margin-inline-end: 0px;
}

.events__photo {
  // display:block;
  // width: 320px;
  // height: 210px;
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
}

.events__figcaption {
  background-color: white;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.div__load__more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
}
.load__more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 290px;
  height: 50px;
  font-size: 18px;
  color: #040C34;
  background-color: transparent;
  border: none;
  cursor: pointer;
  // border: #040C34 solid 1px; 
}
.remove__loadMoreButton {
  display: none;
}
.remove__eventikPoleca {
  display: none;
}
.events__artistName, .events__place, .events__data {
  margin: 0px;
  line-height: 20px;
  text-align: center;
  color: #233D63;
}

.events__artistName {
  font-weight: 700;
  font-size: 18px;
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 14px;
  padding-top: 5px;
}

.events__place {
 
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 7px;
}

.events__place, .events__data {
  font-size: 15px;
  color: #74839b;
}

// .events__calendar::after {
//   position: absolute;
//   content: "";
//     top: 17px;
//     left: 10px;
//     height: 20px;
//     width: 20px;
//     background-image: url(/src/assets/images/calendar.svg);
//     background-repeat: no-repeat;

// }
.event__icons {
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}


.event__svgTicket {
  position: relative;
  left: 8 px;
  top: 1px;
  padding-right: 5px;
  stroke: #233D63;
  fill: #233D63;
  stroke-width: 0.9px;
  // stroke-dasharray: 2,2;
}
.event__moreButton {
  border-radius: 20px;
  color:#040C34;
  padding: 0px 45px 0px 45px;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.4px;
  background-color: white;
  border:#040C34 1.6px solid;
  // border-color: #233D63;
  cursor: pointer;
  height: 40px;

  &:hover {
    // background-color:#FBE307;
    color:#040C34;
    border: #FBE307 1.8px solid;
    font-weight: 600;
  }
 
}

.event__deleteLove {
  border-radius: 20px;
  color:#040C34;
  padding: 0px 35px 0px 35px;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.7px;
  background-color: white;
  border:#040C34 1.6px solid;
  // border-color: #233D63;
  cursor: pointer;
  height: 40px;

  &:hover {
    // background-color:#FBE307;
    color:#040C34;
    border: #FBE307 1.8px solid;
    font-weight: 600;
  }
}

.event__navi {
  padding-top: 50px;
  padding-bottom: 30px;
  color: #8796AC;
  font-size: 17px;
  text-align: left;
}
// .event__img {
//   max-height: 500px;
// }
.event__imgParam {
  max-width: 580px;
}

.event__container {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.event__headings, .event__headName, .event__similarEventText {
  font-size: 33px;
  color:#233D63;
  font-weight: 600;
  margin: 0px;
}
.event__info {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
}
.event__loveShare {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  gap: 10px

}
.event__svgHeart {
  position: relative;
  left: 8px;
  top: 1px;
  padding-right: 15px;
  stroke-width: 0.9px;
  // stroke-dasharray: 2,2;
}
.event__loveButton, .event__shareButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px 8px 15px;
  border: #233D63 1px solid;
  border-radius: 8px;
  background-color:transparent;
  color: #233D63;
  font-size: 15px;
  letter-spacing: 0.4px;
  cursor: pointer;
}
.event__infoWraper {
  display: flex;
  flex-direction: column;
}
.event__description,
.event__place,
.event__date,
.event__price {
  font-size: 21px;
  color: #8796AC;
  line-height: 30px;
}
.event__buyTicket {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  padding: 15px;
  border-radius: 0;
  border: none;
  background-color:#FBE307;
  color:#040C34;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.4px;
  cursor: pointer;
}
a {
  text-decoration: none;
}

a:visited {
  color: #040C34;
}
a:active {
  color: #040C34;
}
a:link {
  color: #040C34;
}

.event__svgBuyTicket {
  padding-left: 70px;
  padding-right: 30px;
  width: 36px;
}
.event__similarEventText {
  font-size: 30px;
  font-weight: 800;
  color:#040C34;
  padding-top: 115px;
  padding-bottom: 30px;
  
}
.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  color:#040C34 !important
}
.modal__btn-primary {
  background-color:#EFF200 !important;
  color:#040C34 !important;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

// KALENDARZ
.flatpickr-calendar {
  width: 357px !important;
  border-radius: 0px !important
}
.flatpickr-months .flatpickr-month {
  font-family: Montserrat, sans-serif;
    padding-bottom: 10px;
  padding-top: 5px;
  font-weight: 600 !important;
  color:#040C34 !important;
  
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: #040C34;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #27B1FF !important
}
span.flatpickr-weekday {
  font-family: Montserrat, sans-serif;
    font-weight: 600 !important;
  color:#040C34 !important;
  
}
.flatpickr-weekdaycontainer {
  padding-bottom: 30px !important;
  padding-top: 30px;
}

.flatpickr-day {
  color:#040C34 !important;
}
.nextMonthDay, .prevMonthDay{
color: #b6b8ba !important;
}

.flatpickr-innerContainer {
  display: block;
  justify-content: center !important
}
.dayContainer {
  padding-bottom: 15px !important;
  padding-top: 10px !important;
}
.flatpickr-day.today {
  border-color:#dde4f3 !important;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color:#dde4f3 !important;
  background:#dde4f3 !important;
  color: #fff;
}

.flatpickr-day:hover
 {
  background:#dde4f3 !important;
  border-color: #dde4f3 !important;
}
.flatpickr-day.selected {
  border-radius: 0;
  background:#dde4f3 !important;
  border-color:#dde4f3 !important;
  border-radius: 50px !important;
  color:#040C34 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: white !important;
}


.footer {
  height: 140px;
  background-color:#FBE307;
  width: 100vw;
  bottom: 0;
  position: absolute;
  
}
.logo__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #040C34;
  padding-top: 35px;
  font-size: 31px;
  font-weight: 700;
}
.logo__textFooter {
  margin: 0px;
  padding-top: 11px;
  // padding-left: 45px;
  // padding-bottom: 5px;
  font-size: 13px;
  color: #040C34;
  font-weight: 400;
  text-align: center;
}
.footer__MJ {
  font-weight: 600;
}
.ticketmaster__giftCard {
 max-width: 65%;
}
.ticketmaster__container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1499px) {

  $gap: 30px;
  $cols: 3;

  .container {
    width: 1000px;
    // padding-left: 150px;
    // padding-right: 150px;
  }
  .input__item {
    width: 160px
  }
 
  .event__wrap {
    width: calc(33% - ($cols - 1)/$cols * $gap);
  }
  .app {
    & .autoComplete_wrapper > .input__search {
      width: 160px;
    }
    & .autoComplete_wrapper > .input__location {
      width: 160px;
    }
  }
  .button__search {
    width: 130px;
  }
  .event__imgParam {
    max-width: 420px;
  }
}


@media (max-width: 1149px) {
  $gap: 30px;
  $cols: 2;

  .container {
    width: 650px;
    // padding-left: 50px;
    // padding-right: 50px;
  }
  .event__wrap {
    width: calc(50% - ($cols - 1)/$cols * $gap);
  }
  .logo__text,
  .logo__textLastword {
    display: none;
  }
  .boxInput {
    padding-top: 5px;
  }
  .input__close::before {
    position: absolute;
    content: "";
      right: 15px;
  }
  .inputs {
    flex-direction: column;
    width: calc((100%));
    margin: 0px;

  }
  // .input__flex {
  //   width: 400px;
  // }
  .input__item {
    width: 400px;
  }
  .input__calendar {
    border-radius: 8px 8px 8px 8px;
  }
  .app {
    & .autoComplete_wrapper > .input__search {
      width: 400px;
      border-radius: 8px 8px 8px 8px;
    }
    & .autoComplete_wrapper > .input__location {
      width: 400px;
      border-radius: 8px 8px 8px 8px;
    }
  }
  .button__search {
    width: 484px;
    border-radius: 8px 8px 8px 8px;
  }
  .event__container {
    display: flex;
    flex-direction: column;
    // justify-content: left;
  }
  .event__headings, .event__headName, .event__similarEventText {
    max-width: 470px;
    font-size: 27px;
  }
  .event__headName {
    padding-top: 20px;
  }
  .event__imgParam {
    max-width: 450px;
  }
  .event__info {
    padding-left:0px;
    // align-items: center;
  }
  .event__buyTicket {
    max-width: 320px;
    font-size: 20px;
  }
  .event__svgBuyTicket {
    padding-left: 50px;
    padding-right: 30px;
    width: 26px;
  }
}

@media (max-width: 799px) {

  $gap: 0px;
  $cols: 1;

  .container {
    width: 310px;
    padding-left: 9px;
    padding-right: 9px;
  }
  .logo {
    font-size: 26px;
  }
  .event__wrap {
    width: calc(100% - ($cols - 1)/$cols * $gap);
    padding-bottom: 0px;
    padding-top: 3px;
  }
  .disable__mobile {
    display: none;
  }
  .inputs {
    flex-direction: column;
    width: calc((100%));
    margin: 0px;
  }
  .wrap__h1Inputs {
    width: 300px;
    margin: 0px;
    padding-top:  30px;
    padding-bottom: 10px;

  }
  
  .autoComplete_wrapper {
    width: 310px;
    padding: 0px;
  }
  .button__ulubione {
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 0px;
    height: 30px;
  }
  .heart{
    width: 18px;
  }
  .yourNotific {
    padding: 0px;
  }
  // .input__location,
  // .input__search,
  // .input__item {
  //   width: 286;
  //   padding-left: 42px;
  //   padding-right: 22px;
  // }
  .input__calendar {
    border-radius: 8px 8px 8px 8px;
  }
  
  .event__polecaText {
    font-size: 23px;
  }

  #datetime-picker {
    width: 246;
    padding-left: 42px;
    padding-right: 22px;
  }
  .input__flex {
    width: 310px;
  }
  // .input__item {
  //   width: 300px;
  // }
  .boxInput {
    padding-top: 1px;
  }
  .app {
    & .autoComplete_wrapper > .input__search {
      width: 246px;
      padding-left: 42px;
      padding-right: 22px;
      border-radius: 8px 8px 8px 8px;
    }
    & .autoComplete_wrapper > .input__location {
      width: 246px;
      padding-left: 42px;
      padding-right: 22px;
      border-radius: 8px 8px 8px 8px;
    }
  }
  .input__close::before {
    position: absolute;
    content: "";
      right: 15px;
      height: 14px;
      width: 14px;
  }


  .button__search {
    width: 310px;
    border-radius: 8px 8px 8px 8px;
  }
  .events__photo {
    width: 100%;
    height: 100%;
    // width: 420px;
    // height: 270px;
  }

  .events__place {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 7px;
  }
  .events__artistName {
    font-size: 17px;
    width: 280px;
    padding-top: 5px;
  }
  .events__place, .events__data {
    font-size: 16px;
    padding-top: 5px;
  }

  .event__moreButton {
    padding: 0px 35px 0px 35px;
    font-size: 12px;
    height: 35px;
  }  


  .event__imgParam {
    padding-top: 30px;
    max-width: 310px;
  }
  .event__headings, .event__headName, .event__similarEventText {
    max-width: 290px;
    font-size: 20px;
  }
  .event__similarEventText {
    padding-top: 50px;
  }
  .event__navi {
    display: none;
  }
  .event__loveButton, .event__shareButton{
    padding: 5px 8px 5px 8px;
    font-size: 12px;
  }
  .event__svgHeart {
    width: 15px;
  }
  .event__description,
  .event__place,
  .event__date,
  .event__price {
    font-size: 17px;
    color: #8796AC;
    line-height: 22px;
  }
  .load__more {
    font-size: 14px;
  }
  .logo__footer {
    font-size: 25px;
  }
  .ticketmaster__giftCard {
    max-width: 100%;
   }
}

